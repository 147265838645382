@import '/src/styles-imports/shared';

.attachmentsModalEdit {
  h5 {
    font-family: $typeFamilyXxs;
    font-weight: $typeWeightXxs;
    font-size: $typeSizeXxs;
    margin-bottom: 8px;
  }

  p {
    font-family: $typeFamilyCopy;
    font-weight: $typeWeightCopy;
    font-size: $typeSizeCopy;
    line-height: $typeLineHeightCopy;
    margin-bottom: 24px;
  }

  ul {
    margin-top: 16px;
    list-style: none;

    li {
      border-bottom: 1px solid $colorGrey4;
      margin-bottom: 16px;
      position: relative;

      form {
        padding-right: 68px;

        > div {
          margin-bottom: 16px;
        }

        > div:last-child {
          margin-bottom: 0;
        }
      }
    }

    li:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}

.editButtons {
  transition: height $animationDurationShort $animationFunction;
  overflow: hidden;
}

.editButtonsActive {
  height: auto;
}

.editButtonsInactive {
  height: 0;
}

.editButtonsWrapper {
  text-align: right;
  line-height: 52px;

  > button:last-child {
    margin: 0 0 16px 16px;
  }
}

.deleteButton {
  border: none;
  background: none;
  border: 1px solid $colorPrimary1;
  border-radius: 100%;
  cursor: pointer;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 4px;
  right: 0;

  &:hover {
    background: $colorGrey4;
  }

  > svg {
    margin-top: 3px;
  }
}
