@import '/src/styles-imports/shared';

.userAssessmentsResults {
  width: 100%;
  background-color: $colorGrey6;
  color: $colorGrey1;

  @include mqSmaller($bpS) {
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeightLarge});
  }

  .gridContainer {
    @include basicGrid;
    padding-bottom: space('M');
    padding-top: space('M');
  }

  .personalInfo {
    grid-column: 1/5;

    .subBlock {
      .profile {
        margin-bottom: space('M');
      }
    }

    .attachments {
      margin-bottom: $spaceM;
    }

    .anchors {
      .title {
        @include type('LabelL');
        color: $colorGrey1;
      }
      a {
        display: block;
        margin-top: space('Xs');
      }
    }
  }

  .assessmentsInfo {
    grid-column: 5/13;
    .title {
      @include type('Xxs');
    }
    .assessment {
      margin-top: space('Xs');
    }
    .noAssessments {
      .description {
        @include type('Copy');
        margin-top: space('Xs');
      }
      button {
        margin-top: space('S');
      }
    }
    @include mqSmaller($bpM) {
      grid-column: 5/9;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: space('M');
    }
  }

}
