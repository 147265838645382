@import '/src/styles-imports/shared';

.bigToggle {
  position: relative;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid $colorPrimary1;
  border-radius: 22px;

  display: flex;
  align-items: center;

  min-width: 320px;

  &.inline {
    display: inline-flex;

    .option {
      padding: space('Xxs') space('M');
    }
  }

  .blue {
    position: absolute;
    transform: translateX(2px);
    width: 50%;
    height: 36px;
    background-color: $colorPrimary1;
    border-radius: 20px;
    &.right {
      transform: translateX(calc(100% - 2px));
    }

    @include transition(transform);
  }

  .option {
    width: 50%;
    text-align: center;
    padding: space('Xxs') space('Xs');
    z-index: zIndex('Buffer1');
    white-space: nowrap;

    @include type('Copy');
    color: $colorPrimary1;

    &:not(.active) {
      cursor: pointer;
    }

    &.active {
      color: $colorWhite;
    }
  }
}
