@import '/src/styles-imports/shared';

/* ProfileSummary */
.profileSummary {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .top {
    .copy {
      @include type('Copy');
      margin-top: space('Xxs');
    }

    a {
      display: block;
      margin-top: space('Xxs');
    }

    .buttons {
      margin-top: space('S');

      .flex {
        display: flex;
        margin-top: space('S');
      }

      button {
        margin-right: space('Xs');
      }

      .statusButton {
        &:focus {
          outline: none;
          border: none;
        }

        .menu {
          position: fixed;
          width: 257px;
          box-sizing: border-box;

          padding: space('Xs') 0;
          margin-top: space('Xs');

          border-radius: 10px;
          border: 1px solid #ececec;
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: $colorWhite;
          z-index: zIndex('Buffer3');

          .menuItem {
            @include type('Copy');
            color: $colorGrey1;
            padding: 16px 16px 14px;
            cursor: pointer;

            @include transition(background-color);

            &:hover {
              background-color: $colorPrimary4;
            }
          }
        }
      }
    }
  }

  .vacancyInfo {
    margin-top: space('M');
    .header {
      padding: space('S') space('Xs');
    }
    .listItem {
      padding: space('Xs') space('Xxs');

      //margins with negative values to decrease the margins of CollapsibleNext content
      margin-right: -(space('Xs'));
      margin-left: -(space('Xxs'));

      border-bottom: 1px solid $colorGrey4;

      &:first-child {
        border-top: 1px solid $colorGrey4;
      }

      .label {
        @include type('Hint');
        color: $colorGrey2;
      }
      .value {
        @include type('Copy');
        margin-top: space('Xxs');

        ul {
          margin-left: space('S');
        }
      }
    }
  }
}

.addCandidatesContent {
  & > div:last-child {
    margin-top: space('S');
  }
}

.removeVacancyContent, .changeStatusContent {
  & > div {
    margin-top: space('S');
    span {
      color: $colorPrimary1;;
    }
  }
}
