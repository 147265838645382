@import '/src/styles-imports/shared';

/* LineDiagram */
.lineDiagram {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  svg {
    width: 100%;
    overflow: initial;
  }

  .grey {
    fill: $colorGrey3;
  }

  .marker {
    transition: cx 0.3s ease-in-out;
    &.distribution {
      fill: #92B4F9;
    }
    &.primary, &.blue {
      fill: $colorPrimary1;
    }
    &.yellow {
      fill: $colorYellow1;
    }
    &.green {
      fill: $colorGreen1;
    }
    &.red {
      fill: $colorRed1;
    }
    &.purple {
      fill: $colorPurple1;
    }
    @include diagramFills();
  }

  .range {
    transition: width 0.3s ease-in-out, x 0.3s ease-in-out;
    &.primary, &.blue {
      stroke: $colorPrimary3;
    }
    &.yellow {
      stroke: $colorYellow3;
    }
    &.green {
      stroke: $colorGreen2;
    }
    &.red {
      stroke: $colorRed2;
    }
    &.purple {
      stroke: $colorPurple2;
    }

  }
}
