@import '/src/styles-imports/shared';

/* STANDALONE WRAPPER */
.standaloneWrapper {
  position: fixed; // to break out of mainLayout
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $colorWhite;
  z-index: zIndex('Standalone');

  /* INTERACTION */

  /* MODIFIERS */

  /* CHILDREN */
  .header {
    z-index: 2;
    width: 100%;
    height: $headerHeightStandalone;
  }

  .page {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: clip;

    height: calc(100% - #{$headerHeightStandalone});

    .content {
      flex: 1;
    }
    .footer {
      @include gridMargins;  
      height: $footerHeight;
      background-color: $colorWhite;

      // same animation as PageWrapper
      @include animation(slideUp);
      animation-fill-mode: forwards;
    }
  }
  
  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    .header {
      height: $headerHeightStandaloneXs;
    }
    .page {
      height: calc(100% - #{$headerHeightStandaloneXs});
      .footer {
        height: $footerHeightXs;
      }
    }
  }
}
